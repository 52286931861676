<template>
  <v-container tag="section">
    <base-material-card
      color="primary"
      icon="mdi-bank"
      :title="title"
      inline
      class="px-5 py-4 ml-0"
    >
      <template v-slot:after-heading>
        <div class="display-2 font-weight-light">
          Gerenciar Manuais e responsáveis
        </div>
      </template>
      <v-row class="mx-auto mt-5">
        <v-col cols="auto" class="ml-2">
          <v-btn
            color="primary"
            dark
            elevation="1"
            relative
            text
            medium
            @click="baixarManual()"
            :loading="loading"
          >
            <v-icon left size="30px"> mdi-file-pdf-box </v-icon>
            Manual da Ferramenta
          </v-btn>
        </v-col>
        <v-col cols="auto" class="ml-2">
          <v-btn
            color="primary"
            dark
            elevation="1"
            relative
            text
            medium
            :loading="loading"
            @click="baixarResponsaveis()"
          >
            <v-icon left size="30px"> mdi-microsoft-excel </v-icon>
            Responsáveis por Tema & Manual ANEEL
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="mx-auto mt-5">
        <v-col cols="6" sm="3" class="ml-2">
          <v-select
            :items="[
              { text: 'Manual', value: 'manual' },
              { text: 'Responsáveis Tema', value: 'responsaveis' },
            ]"
            hide-details
            outlined
            v-model="tipoDeCadastro"
            label="Tipo de Cadastro"
          ></v-select>
        </v-col>
        <v-col cols="12">
          <upload-files
            id="fs-upload-importacao-dados"
            :allowedFiles="allowedFiles[tipoDeCadastro]"
            :allowMultipleUploads="false"
            @file:uploaded="handleFileUploaded"
          >
            <template v-slot:upload-alert-extra-information>
              <slot
                v-if="tipoDeCadastro === 'geral'"
                name="cadatro-geral-extra-info"
              />
              <slot
                v-else-if="tipoDeCadastro === 'municipios'"
                name="cadatro-municipios-extra-info"
              />
            </template>
          </upload-files>
        </v-col>
      </v-row>
      <v-row class="mx-auto ml-2" v-if="lastUploadedFile">
        <v-col cols="12">
          <v-text-field
            label="Arquivo enviado"
            outlined
            readonly
            v-model="lastUploadedFile.arquivo"
          />
        </v-col>
      </v-row>
      <v-row dense justify="end">
        <v-btn
          :disabled="!canSubmit"
          :loading="loading"
          color="success"
          elevation="1"
          @click="uploadFile()"
        >
          Cadastrar
        </v-btn>
      </v-row>
    </base-material-card>
  </v-container>
</template>


<script>
import GovernancaService from "@/services/GovernancaService.js";

export default {
  components: {
    SelectEncoding: () => import("@/components/general/SelectEncoding.vue"),
    UploadFiles: () => import("@/components/general/UploadFiles/Index.vue"),
  },
  props: {},
  data() {
    return {
      encoding: "UTF-8",
      allowedFiles: {
        manual: ["pdf"],
        responsaveis: ["xlsx"],
      },
      uploadedFiles: [],
      loading: false,
      tipoDeCadastro: "manual",
    };
  },
  computed: {
    lastUploadedFile() {
      return this.uploadedFiles[this.uploadedFiles.length - 1];
    },
    canSubmit() {
      return (
        this.tipoDeCadastro != "" &&
        this.lastUploadedFile !== undefined &&
        this.allowedFiles[this.tipoDeCadastro].includes(
          this.lastUploadedFile.extensao
        )
      );
    },
  },
  methods: {
    handleFileUploaded(event) {
      const { files } = event;
      files.forEach((file) => this.uploadedFiles.push(file));
    },
    uploadManual() {
      GovernancaService.uploadManual(this.lastUploadedFile.id)
        .then((res) => {
          this.$toast.success("Arquivo salvo com sucesso!.", "", {
            position: "topRight",
          });
          this.uploadedFiles = [];
        })
        .catch((error) => {
          this.$toast.error("Erro ao salvar o arquivo.", "", {
            position: "topRight",
          });
        })
        .finally(() => (this.loading = false));
    },
    uploadResponsaveis() {
      GovernancaService.uploadResponsaveis(this.lastUploadedFile.id)
        .then((res) => {
          this.$toast.success("Arquivo salvo com sucesso!.", "", {
            position: "topRight",
          });
          this.uploadedFiles = [];
        })
        .catch((error) => {
          this.$toast.error("Erro ao salvar o arquivo.", "", {
            position: "topRight",
          });
        })
        .finally(() => (this.loading = false));
    },
    baixarManual() {
      GovernancaService.getLinkDownloadManualIndger()
        .then((res) => {
          window.open(res.data.link, "_blank").focus();
        })
        .catch((error) => {
          console.log(error);
          this.$toast.error("Erro no download do arquivo.", "", {
            position: "topRight",
          });
        })
        .finally(() => (this.loading = false));
    },
    baixarResponsaveis() {
      GovernancaService.getLinkDownloadResponsaveisTemas()
        .then((res) => {
          window.open(res.data.link, "_blank").focus();
        })
        .catch((error) => {
          console.log(error);
          this.$toast.error("Erro no download do arquivo.", "", {
            position: "topRight",
          });
        })
        .finally(() => (this.loading = false));
    },
    uploadFile() {
      if (this.tipoDeCadastro == "manual") {
        this.uploadManual();
      } else if (this.tipoDeCadastro == "responsaveis") {
        this.uploadResponsaveis();
      }
    },
  },
};
</script>