import APIService from './APIService';

export default {
  getLinkDownloadManualIndger() {
    return APIService.apiCall().get(`/governanca/get-link-download-manual-indger`);
  },
  getLinkDownloadResponsaveisTemas() {
    return APIService.apiCall().get(`/governanca/get-link-download-responsaveis-temas`);
  },
  uploadManual(arquivo_id) {
    return APIService.apiCall().post(`/governanca/upload-manual?arquivo_id=${arquivo_id}`);
  },
  uploadResponsaveis(arquivo_id) {
    return APIService.apiCall().post(`/governanca/upload-responsaveis?arquivo_id=${arquivo_id}`);
  },
  getResponsaveis(){
    return APIService.apiCall().get(`/governanca/get-responsaveis`);
  }
}